/**
 * SeoComponent component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { convertRichTextToPlain } from "../../utils/text"
import { getHTMLLang } from "../../utils/langs"
import { DEFAULT_LANG, SITE_URL } from "../../../propreties"

function SeoComponent({
  description,
  lang,
  meta = [],
  title,
  keywords,
  image,
  canonical,
}) {
  if (typeof title === "object") {
    title = convertRichTextToPlain(title)
  }
  if (typeof description === "object") {
    description = convertRichTextToPlain(description)
  }
  if (keywords && keywords.length) {
    meta.push({
      name: "Keywords",
      content: keywords.map(item => item.keyword).join(", "),
    })
  }
  if (image && image.url) {
    meta.push({
      name: "Image",
      content: image.url,
    })
  }
  // Extract the first 300 characters of the description
  if (description) description = description.substring(0, 300)

  const getAbsoluteUrl = path => {
    if (path && path.startsWith("/")) {
      return SITE_URL + path
    }
    return path
  }

  lang = lang ? getHTMLLang(lang) : getHTMLLang(DEFAULT_LANG)
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      {canonical && <link rel="canonical" href={getAbsoluteUrl(canonical)} />}
    </Helmet>
  )
}

SeoComponent.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SeoComponent.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
}

export default SeoComponent
