import React from "react"
import { RichText } from "../common/rich-text.component"
import { Section } from "../common/section.component"
import { graphql, StaticQuery } from "gatsby"
import { convertRichTextToPlain, reduceHeaderSize } from "../../utils/text"
import { Collection } from "../common/collection.component"
import { withPreview } from "gatsby-source-prismic-graphql"
import { linkFragment } from "../../link-resolver"

const query = graphql`
    query childPages {
        prismic {
            allContent_pages {
                edges {
                    node {
                        _meta {
                            uid
                            type
                            lang
                            tags
                        }
                        parent {
                            ... on PRISMIC_Content_page {
                                _meta {
                                    uid
                                    type
                                    lang
                                }
                                parent {
                                    ... on PRISMIC_Content_page {
                                        _meta {
                                            uid
                                            type
                                            lang
                                        }
                                    }
                                }
                            }
                        }
                        description
                        background_image
                        text
                        title
                        preview_image
                    }
                }
            }
        }
    }
`

export const ItemsCollection = ({ slice }) => {
  const bgImage = slice.primary && slice.primary.bg_image
  const bgColor = slice.primary && slice.primary.bg_color
  return (
    <Section className="py-5" backgroundImage={bgImage} backgroundColor={bgColor}>
      <div className={`features-slice ${slice.label ? "features-slice-" + slice.label : ""}`}>
        <div className="container feature-container-header text-center mb-5">
          <RichText render={slice.primary.title}/>
          <RichText render={slice.primary.text}/>
        </div>
        <StaticQuery
          query={query}
          render={
            withPreview(data => {
              const pages = data.prismic.allContent_pages.edges.filter(item => {
                return item.node._meta.tags.find(tag => {
                  return slice.fields.map(field => field.tag).indexOf(tag) > -1
                })
              }).map(page => {
                const { link_style, link_text } = slice.primary
                const { background_image, description, title, text, preview_image, _meta, parent } = page.node
                return {
                  title: reduceHeaderSize(title, 3),
                  text: description || convertRichTextToPlain(text),
                  image: preview_image || background_image,
                  link_style,
                  link_text,
                  link: {
                    _linkType: "Link.document",
                    _meta,
                    parent,
                  },
                }
              })
              return <Collection type={slice.label} items={pages}/>
            }, query, [linkFragment])
          }
        />
      </div>
    </Section>
  )
}
